import I from 'phenomenon';
var M = 'phi',
	A = 'theta',
	T = 'mapSamples',
	c = 'mapBrightness',
	O = 'baseColor',
	s = 'markerColor',
	N = 'glowColor',
	L = 'markers',
	G = 'diffuse',
	u = 'devicePixelRatio',
	X = 'dark',
	m = {
		[M]: 'y',
		[A]: 'z',
		[T]: 'j',
		[c]: 'B',
		[O]: 'J',
		[s]: 'K',
		[N]: 'w',
		[G]: 'C',
		[X]: 'D',
	},
	{ PI: S, sin: P, cos: f } = Math,
	x = (E) =>
		[].concat(
			...E.map((n) => {
				let [_, a] = n.location;
				(_ = (_ * S) / 180), (a = (a * S) / 180 - S);
				let e = f(_);
				return [-e * f(a), P(_), e * P(a), n.size];
			}),
			[0, 0, 0, 0]
		),
	h = (E, n) => {
		let _ = (e, t) => ({
				type: e,
				value: n[t],
			}),
			a = new I({
				canvas: E,
				context: {
					alpha: !0,
					stencil: !1,
					antialias: !0,
					depth: !1,
					preserveDrawingBuffer: !1,
				},
				settings: {
					[u]: n[u] || 1,
					onSetup: (e) => {
						let t = e.RGB,
							r = e.UNSIGNED_BYTE,
							o = e.TEXTURE_2D,
							R = e.createTexture();
						e.bindTexture(o, R),
							e.texImage2D(
								o,
								0,
								t,
								1,
								1,
								0,
								t,
								r,
								new Uint8Array([0, 0, 0, 0])
							);
						let i = new Image();
						(i.onload = () => {
							e.bindTexture(o, R),
								e.texImage2D(o, 0, t, t, r, i),
								e.generateMipmap(o);
							let d = e.getParameter(e.CURRENT_PROGRAM),
								U = e.getUniformLocation(d, 'E');
							e.texParameteri(o, e.TEXTURE_MIN_FILTER, e.NEAREST),
								e.texParameteri(o, e.TEXTURE_MAG_FILTER, e.NEAREST),
								e.uniform1i(U, 0);
						}),
							(i.src =
								'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAACAAQAAAADMzoqnAAAAAXNSR0IArs4c6QAABA5JREFUeNrV179uHEUAx/Hf3JpbF+E2VASBsmVKTBcpKJs3SMEDcDwBiVJAAewYEBUivIHT0uUBIt0YCovKD0CRjUC4QfHYh8hYXu+P25vZ2Zm9c66gMd/GJ/tz82d3bk8GN4SrByYF2366FNTACIAkivVAAazQdnf3MvAlbNUQfOPAdQDvSAimMWhwy4I2g4SU+Kp04ISLpPBAKLxPyic3O/CCi+Y7rUJbiodcpDOFY7CgxCEXmdYD2EYK2s5lApOx5pEDDYCUwM1XdJUwBV11QQMg59kePSCaPAASQMEL2hwo6TJFgxpg+TgC2ymXPbuvc40awr3D1QCFfbH9kcoqAOkZozpQo0aqAGQRKCog/+tjkgbNFEtg2FffBvBGlSxHoAaAa1u6X4PBAwDiR8FFsrQgeUhfJTSALaB9jy5NCybJPn1SVFiWk7ywN+KzhH1aKAuydhGkbEF4lWohLXDXavlyFgHY7LBnLRdlAP6BS5Cc8RfVDXbkwN/oIvmY+6obbNeBP0JwTuMGu9gTzy1Q4RS/cWpfzszeYwd+CAFrtBW/Hur0gLbJGlD+/OjVwe/drfBxkbbg63dndEDfiEBlAd7ac0BPe1D6Jd8dfbLH+RI0OzseFB5s01/M+gMdAeluLOCAuaUA9Lezo/vSgXoCX9rtEiXnp7Q1W/CNyWcd8DXoS6jH/YZ5vAJEWY2dXFQe2TUgaFaNejCzJ98g6HnlVrsE58sDcYqg+9XY75fPqdoh/kRQWiXKg8MWlJQxUFMPjqnyujhFBE7UxIMjyszk0QwQlFsezImsyvUYYYVED2pk6m0Tg8T04Fwjk2kdAwSACqlM6gRRt3vQYAFGX0Ah7Ebx1H+MDRI5ui0QldH4j7FGcm90XdxD2Jg1AOEAVAKhEFXSn4cKUELurIAKwJ3MArypPscQaLhJFICJ0ohjDySAdH8AhDtCiTuMycH8CXzhH9jUACAO5uMhoAwA5i+T6WAKmmAqnLy80wxHqIPFYpqCwxGaYLt4Dyievg5kEoVEUAhs6pqKgFtDQYOuaXypaWKQfIuwwoGSZgfLsu/XAtI8cGN+h7Cc1A5oLOMhwlIPXuhu48AIvsSBkvtV9wsJRKCyYLfq5lTrQMFd1a262oqBck9K1V0YjQg0iEYYgpS1A9GlXQV5cykwm4A7BzVsxQqo7E+zCegO7Ma7yKgsuOcfKbMBwLC8wvVNYDsANYalEpOAa6zpWjTeMKGwEwC1CiQewJc5EKfgy7GmRAZA4vUVGwE2dPM/g0xuAInE/yG5aZ8ISxWGfYigUVbdyBElTHh2uCwGdfCkOLGgQVBh3Ewp+/QK4CDlR5Ws/Zf7yhCf8pH7vinWAvoVCQ6zz0NX5V/6GkAVV+2/5qsJ/gU8bsxpM8IeAQAAAABJRU5ErkJggg==');
					},
				},
			});
		return (
			a.add('', {
				vertex:
					'attribute vec3 aPosition;uniform mat4 uProjectionMatrix;uniform mat4 uModelMatrix;uniform mat4 uViewMatrix;void main(){gl_Position=uProjectionMatrix*uModelMatrix*uViewMatrix*vec4(aPosition,1.);}',
				fragment:
					'precision highp float;uniform vec2 t;uniform vec3 J,K,w;uniform vec4 x[64];uniform float y,z,j,A,B,C,D;uniform sampler2D E;float F=1./j;mat3 G(float a,float b){float c=cos(a),d=cos(b),e=sin(a),f=sin(b);return mat3(d,f*e,-f*c,0.,c,e,f,d*-e,d*c);}vec3 u(vec3 a,out float v){a=a.xzy;float o=max(2.,floor(log2(2.236068*j*3.141593*(1.-a.z*a.z))*.72021));vec2 e=floor(pow(1.618034,o)/2.236068*vec2(1.,1.618034)+.5),b=fract((e+1.)*.618034)*6.283185-3.883222,c=-2.*e,d=vec2(atan(a.y,a.x),a.z-1.),p=floor(vec2(c.y*d.x-b.y*(d.y*j+1.),-c.x*d.x+b.x*(d.y*j+1.))/(b.x*c.y-c.x*b.y));float m=3.141593;vec3 q;for(float f=0.;f<4.;f+=1.){vec2 r=vec2(mod(f,2.),floor(f*.5));float h=dot(e,p+r);if(h>j)continue;float i=h/1.618034,k=(i-floor(i/3000.)*3000.)*6.283185,g=1.-2.*h*F,l=sqrt(1.-g*g);vec3 n=vec3(cos(k)*l,sin(k)*l,g);float s=length(a-n);if(s<m)m=s,q=n;}v=m;return q.xzy;}void main(){vec2 a=gl_FragCoord.xy/t*2.-1.;a.x*=t.x/t.y;float c=dot(a,a),b;vec3 d=vec3(0.,0.,1.),e=normalize(vec3(a,sqrt(.64-c)));if(c<=.64){vec3 f=e*G(z,y),g=u(f,b);float k=asin(g.y),h=acos(-g.x/cos(k));h=g.z<0.?-h:h;float s=texture2D(E,vec2(h*.5/3.141593,-(k/3.141593+.5))).x,v=smoothstep(8e-3,0.,b),l=dot(e,d),r=pow(l,C)*B,m=s*v*r,L=mix((1.-m)*pow(l,.4),m,D)+.1;gl_FragColor=vec4(J*L,1.);int M=int(A);float n=0.;for(int i=0;i<64;i++){if(i>=M)break;vec4 o=x[i];vec3 p=o.xyz,H=p-f;float q=o.w;if(dot(H,H)>q*q*4.)continue;vec3 N=u(p,b);b=length(N-f),b<q?n+=smoothstep(q*.5,0.,b):0.;}n=min(1.,n*r),gl_FragColor.xyz=mix(gl_FragColor.xyz,K,n),gl_FragColor.xyz+=pow(1.-dot(e,d),4.)*w;}float I=pow(dot(normalize(vec3(-a,sqrt(1.-c))),d),4.)*smoothstep(.1,1.,.2/(c-.64));gl_FragColor+=vec4(I*w,I);}',
				uniforms: {
					t: {
						type: 'vec2',
						value: [n.width, n.height],
					},
					y: _('float', M),
					z: _('float', A),
					j: _('float', T),
					B: _('float', c),
					J: _('vec3', O),
					K: _('vec3', s),
					C: _('float', G),
					w: _('vec3', N),
					D: _('float', X),
					x: {
						type: 'vec4',
						value: x(n[L]),
					},
					A: {
						type: 'float',
						value: n[L].length,
					},
				},
				mode: 4,
				geometry: {
					vertices: [
						{
							x: -100,
							y: 100,
							z: 0,
						},
						{
							x: -100,
							y: -100,
							z: 0,
						},
						{
							x: 100,
							y: 100,
							z: 0,
						},
						{
							x: 100,
							y: -100,
							z: 0,
						},
						{
							x: -100,
							y: -100,
							z: 0,
						},
						{
							x: 100,
							y: 100,
							z: 0,
						},
					],
				},
				onRender: ({ uniforms: e }) => {
					let t = {};
					if (n.onRender) {
						n.onRender(t);
						for (let r in m) t[r] !== void 0 && (e[m[r]].value = t[r]);
						t[L] !== void 0 &&
							((e['x'].value = x(t[L])), (e['A'].value = t[L].length)),
							t.width && t.height && (e['t'].value = [t.width, t.height]);
					}
				},
			}),
			a
		);
	};
export { h as default };
