import './styles.css';
import { isMobile } from 'react-device-detect';
import createGlobe from './cobe';

let phi = 2.68;
let canvas = document.getElementById('cobe');

var gl = canvas.getContext('webgl');
var markerSize = 0.02;

const globe = createGlobe(canvas, {
	devicePixelRatio: isMobile ? 2 : 1,
	width: isMobile ? 700 : 1000,
	height: isMobile ? 700 : 1000,
	phi: 0,
	theta: 0,
	dark: 1,
	diffuse: 2,
	mapSamples: 26000,
	mapBrightness: 16,
	baseColor: [0.07, 0.07, 0.07],
	markerColor: [0.847, 0.129, 0.282],
	glowColor: [0, 0, 0],
	// glowColor: [0.0823, 0.1137, 0.2313],
	markers: [
		// latitude, longitude
		{ location: [13.756331, 100.501762], size: markerSize }, //Bangkok
		{ location: [1.352083, 103.819839], size: markerSize }, //Singapore
		{ location: [27.717245, 85.323959], size: markerSize }, //Kathmandu
		{ location: [45.5202471, -122.6741949], size: markerSize }, //Portland
		{ location: [60.205491, 24.6559], size: markerSize }, //Espo
		{ location: [59.329323, 18.068581], size: markerSize }, //Stockholm
		{ location: [28.538336, -81.379236], size: markerSize }, //Orlando
		{ location: [25.76168, -80.19179], size: markerSize }, //Miami
		{ location: [40.741895, -73.989308], size: markerSize }, //NYC
		{ location: [-33.8548157, 151.2164539], size: markerSize }, //Sydney
		{ location: [-37.8142176, 144.9631608], size: markerSize }, //Melbourne
		{ location: [28.209538, 83.991402], size: markerSize }, //Pokhara
		{ location: [3.1516964, 101.6942371], size: markerSize }, //Kuala Lumpur
		{ location: [37.5666791, 126.9782914], size: markerSize }, //Seoul
		{ location: [35.6828387, 139.7594549], size: markerSize }, //Tokyo
		{ location: [19.0759899, 72.8773928], size: markerSize }, //Bombay
		{ location: [12.9767936, 77.590082], size: markerSize }, //Bangalore
		{ location: [25.2653471, 55.2924914], size: markerSize }, //Dubai
		{ location: [32.0852997, 34.7818064], size: markerSize }, //Tel Aviv
		{ location: [50.8465573, 4.351697], size: markerSize }, //Brussels
		{ location: [38.7077507, -9.1365919], size: markerSize }, //Lisbon
		{ location: [40.4167047, -3.7035825], size: markerSize }, //Madrid
		{ location: [48.8588897, 2.320041], size: markerSize }, //Paris
		{ location: [47.3744489, 8.54104224], size: markerSize }, //Zurich
		{ location: [46.2017559, 6.1466014], size: markerSize }, //Geneva
		{ location: [52.3727598, 4.8936041], size: markerSize }, //Amsterdam
		{ location: [52.5170365, 13.3888599], size: markerSize }, //Berlin
		{ location: [-25.693905, -54.4365376], size: markerSize }, //Iguaza falls
		{ location: [37.6452283, 138.7669125], size: markerSize }, //Niigata
		{ location: [33.3939924, 126.5626653], size: markerSize }, //Jeju Islands
		{ location: [-26.205, 28.049722], size: markerSize }, //Johannesburg
		{ location: [31.2638905, -98.5456116], size: markerSize }, //Texas
		{ location: [43.157285, -77.615214], size: markerSize }, //Rochester
		{ location: [35.8989818, 14.5136759], size: markerSize }, //Valletta
		{ location: [41.8755616, -87.6244212], size: markerSize }, //Chicago
		{ location: [38.8950368, -77.0365427], size: markerSize }, //Washington
		{ location: [37.7790262, -122.419906], size: markerSize }, //San Francisco
		// { location: [27.4713546, 89.6336729], size: markerSize }, //Thimpu
	],

	onRender: (state) => {
		// Called on every animation frame.
		// `state` will be an empty object, return updated params.
		state.phi = phi;
		// state.width = w;
		// state.height = h;
		phi += 0.002;
		// console.log(state);
	},
});
